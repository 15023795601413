<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Verify Package Purchase
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">


            <div class="mb-3">
              <label for="Client Phone" class="form-label">Verify Code</label>
              <input type="text" class="form-control" id="verifycode" placeholder="enter code"
                v-model="verificationCode" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click.prevent="VerifyClientPurchase" class="btn btn-primary" :disabled="!isDisbaled">
              <b-spinner v-if="loading" type="grow"></b-spinner>
              <span v-else>Verify Purchase</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <nav class="nav">
      <a class="nav-link btn btn-outline-warning mr-3" :class="{ active: showactiveTab === 1 }" @click="changeTab(1)"
        href="#">Food Hub Packages</a>
      <a class="nav-link btn btn-outline-primary" :class="{ active: showactiveTab === 2 }" @click="changeTab(2)"
        href="#">Client Purchased Packs</a>
    </nav>

    <div class="row" v-if="tab == 1">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"></h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-md-12 d-flex justify-content-end">
                <router-link to="/add-foodhub-pack" class="btn btn-sm mb-5 btn-success">
                  Add FoodHub Package
                </router-link>
              </div>

              <div class="row align-items-center">
                <div class="col">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" @click="changeTab(1)" href="#">
                        All Packages
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link  fw-semibold" @click="changeTab(3)" href="#">
                        Expired Packages
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6  d-flex justify-content-end">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" @keyup.enter="searchHelthHub" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->

            <div class="row">
              <b-table :items="tableData" :fields="fields">
                <template v-slot:cell(effectiveStartDate)="row">
                  {{ getFormattedDate(row.item.effectiveStartDate) }}
                </template>
                <template v-slot:cell(effectiveEndDate)="row">
                  {{ getFormattedDate(row.item.effectiveEndDate) }}
                </template>
                <template v-slot:cell(Action)="row">
                  <router-link :to="`/edit-foodhub-pack/${row.item.id}`" type="button" class="btn btn-success">
                    <i class="fa fa-pen" aria-hidden="true" title="Edit"></i>
                  </router-link>
                </template>
              </b-table>
              <div class="d-flex justify-content-center text-center">
                <b-spinner v-if="loading" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
              </div>
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="tab == 3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"></h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-md-12 d-flex justify-content-end">
                <router-link to="/add-foodhub-pack" class="btn btn-sm mb-5 btn-success">
                  Add FoodHub Package
                </router-link>
              </div>

              <div class="row align-items-center">
                <div class="col">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" @click="changeTab(1)" href="#">
                        All Packages
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link  fw-semibold" @click="changeTab(3)" href="#">
                        Expired Packages
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6  d-flex justify-content-end">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" @keyup.enter="searchHelthHub" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->

            <div class="row">
              <b-table :items="expiredProducts" :fields="fields">
                <template v-slot:cell(effectiveStartDate)="row">
                  {{ getFormattedDate(row.item.effectiveStartDate) }}
                </template>
                <template v-slot:cell(effectiveEndDate)="row">
                  {{ getFormattedDate(row.item.effectiveEndDate) }}
                </template>
                <template v-slot:cell(Action)="row">
                  <button @click="getData(row.item.id)" type="button" data-toggle="modal" data-target="#EditModal"
                    class="btn btn-success">
                    <i class="fa fa-pen" aria-hidden="true" title="Edit"></i>
                  </button>
                </template>
              </b-table>
              <div class="d-flex justify-content-center text-center">
                <b-spinner v-if="loading" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
              </div>
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="tab == 2">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"></h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <!-- <div class="col-md-12 d-flex justify-content-end">
                <button
                  data-toggle="modal"
                  data-target="#exampleModal"
                  class="btn btn-sm mb-5 btn-success"
                  type="button"
                >
                  Verify Package Purchase
                </button>
              </div> -->

              <!-- Modal -->

              <div class="col-sm-12  col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage"
                      size="sm" :options="pageOptions"></b-form-select>entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6  d-flex justify-content-end">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filterclient" @keyup.enter="searchClientPurchase" type="search"
                      placeholder="Search..." class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <b-table :items="clientpack" :fields="clientfields">
              <template v-slot:cell(Action)="row">
                <button 
                @click="openVerifyPackModal(row.item)" 
                v-if="row.item.packageUsageStatus == 'New'"
                 type="button"
                  class="btn btn-success">
                  <i class="fas fa-check-circle" aria-hidden="true" title="Edit"></i>
                </button>
              </template>
            </b-table>

            <div class="d-flex justify-content-center text-center">
              <b-spinner v-if="loading" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import moment from "moment";
import $ from "jquery";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Health Packs",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      clientpack: [],
      expiredProducts: [],
      title: "",
      items: [
        {
          text: "Oonpay",
          href: "/"
        },

        {
          text: "Food Packs",
          active: true
        }
      ],
      searchterm: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 2,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterclient: null,
      filterOn: [],
      sortBy: "fullName",
      sortDesc: false,
      fields: [
        { key: "fullName", sortable: true },
        { key: "superCategory", sortable: true },
        { key: "subCategory", sortable: true },
        { key: "cost" },
        { key: "availableStock" },
        { key: "effectiveStartDate", sortable: true },
        { key: "effectiveEndDate", sortable: true },

        { key: "Action" }
      ],
      clientfields: [
        { key: "beneficiaryFullName", sortable: true },
        { key: "beneficiaryPhoneNumber", sortable: true },
        { key: "packageFullName", sortable: true },
        { key: "packageSuperCategory", sortable: true },
        { key: "packageSubCategory", sortable: true },
        { key: "packageQuantity", sortable: true },
        { key: "packageUsageStatus" },
        { key: "Action"}
      ],

      tab: 1,
      showactiveTab: 1,
      selectedcurrencyIds: [],
      countries: [],
      countrycode: "Select country code",
      phoneNumber: "",
      verificationCode: "",
      loading: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.gethealth_hub_req_data
        ? this.gethealth_hub_req_data?.item1[0]?.totalRecords
        : 0;
    },

    totalRecords() {
      return this.gethealth_hub_req_data
        ? this.gethealth_hub_req_data?.item2[0]?.totalRecords
        : 0;
    },
    isDisbaled() {
      return this.verificationCode.length > 0;
    }
  },

  mounted() {
    this.getFoodHubPacks();

    this.totalRows = this.items.length;

    this.$store
      .dispatch("dashboard/GetAllCountries")
      .then((res) => (this.countries = res));
  },
  methods: {
    changeTab(data) {
      this.showactiveTab = data;
      this.tab = data;
    },

    
    getFoodHubPacks() {
      this.loading = true
      this.$store.dispatch("foodhub/getRequiredData").then((res) => {
        this.tableData = res.data.item1;
        this.clientpack = res.data.item2;
        this.expiredProducts = res.data.item1.filter(
          (el) => el.isExpired == true
        );
        this.loading = false
      });
    },

    openVerifyPackModal(data){
      this.phoneNumber = data.beneficiaryPhoneNumber
       console.log(data)
      $("#exampleModal").modal("show");
    },

    VerifyClientPurchase() {
      // let newPhone = this.phoneNumber;
      // if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
      //   var phone = newPhone.substring(1);
      // }
      // let main_number = `${this.countrycode}${phone}`;

      const payload = {
        verificationCode: this.verificationCode,
        phoneNumber: this.phoneNumber
      };

      // console.log(payload);
      this.loading = true;

      this.$store
        .dispatch("foodhub/verifyClientPurchase", payload)
        .then((response) => {
          if (response.status == 200) {
            this.loading = false;
            this.getFoodHubPacks();
            Swal.fire("Success!", "Client Purchase verified", "success");
            window.location.reload();
          }
        })
        .catch((err) => {
          Swal.fire("error!", err.response.data, "error");
          this.loading = false;
        });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getFormattedDate(date) {
      return moment(date).format("YYYY-MM-DD");
    }
  }
};
</script>

<style scoped>
.btn-warning {
  background: #f15825 !important;
}
</style>
